<template>
  <div style="padding: 15px; background-color: white;">
    <table style="width: 100%; text-align: center; " id="detailPeriodTable">
      <tr style="text-align: left; border-bottom: 2px solid darkgray;">
        <!--              1-->
        <th style="width: 70px">
          <div
            class="tooltip-parent"
            data-tooltip-text="※ 가장 큰 범주에 해당하는 영역입니다."
          >
            섹션
            <v-icon style="font-size: 15px; color: red"
              >mdi-comment-question-outline</v-icon
            >
          </div>
        </th>
        <!--              2-->
        <th style="width: 70px;">
          <div
            class="tooltip-parent"
            data-tooltip-text="※ 구체적인 범주에 해당하는 영역입니다."
          >
            카테고리
            <v-icon style="font-size: 15px; color: red"
              >mdi-comment-question-outline</v-icon
            >
          </div>
        </th>
        <!--              3-->
        <th style="width: 8%">
          <div
            class="tooltip-parent"
            data-tooltip-text="※ 빈도가 급격하게 변화한 단어입니다."
          >
            단어
            <v-icon style="font-size: 15px; color: red"
              >mdi-comment-question-outline</v-icon
            >
          </div>
        </th>
        <!--              4-->
        <th
          style="width: 70px; font-weight: bold; color: red; text-align: center; "
        >
          <div
            class="change_rate_col tooltip-parent"
            data-tooltip-text="※ 색이 많이 채워질 수록 빈도의 변화가 급격한 단어입니다. "
            style="position: relative; border: 1px solid red; border-radius: 5px; margin-bottom: 2px; margin: 5px; padding: 3px"
          >
            <div>
              상승률<v-icon style="font-size: 15px; color: red"
                >mdi-comment-question-outline</v-icon
              >
            </div>
          </div>
        </th>
        <!--              5-->
        <th style=""></th>
        <!--              6-->
        <th style="width: 50px">
          <div>최근</div>
          <div>1주</div>
        </th>

        <!--              7-->
        <th style=""></th>
        <!--              8-1-->
        <th style="width: 150px; text-align: center">
          <div>{{ periodColName.col1 }}</div>
        </th>
        <!--              8-2-->
        <th style="width: 150px; text-align: center">
          <div>{{ periodColName.col2 }}</div>
        </th>
        <!--              8-3-->
        <th style="width: 150px; text-align: center">
          <div>{{ periodColName.col3 }}</div>
        </th>

        <!--              9-->
        <th style=""></th>
        <!--              10-->
        <th style="text-align: center;">
          <div>
            <div
              style="display: inline-block; background-color: darkred; height: 3px; width: 12px; margin-bottom: 3px; margin-right: 2px"
            ></div>
            Current grade
          </div>
          <div>
            <div
              style="display: inline-block; position: relative; background-color: darkred; height: 10px; width: 3px; top: 1px; margin-left: 5px;margin-right: 6px"
            ></div>
            Target grade
          </div>
          <div>
            <div
              style="display: inline-block; position: relative; background-color: darkred; height: 11px; width: 12px; margin-bottom: 3px; margin-right: 2px;background-color: #ff000033; border-top: 2px solid #ffb3b3; border-bottom: 2px solid #ffb3b3; top: 4px"
            ></div>
            Previous year
          </div>
        </th>
        <!--              11-->
        <th>
          <div>
            <v-icon
              style="width: 7px;  height: 10px; font-size: 30px; color: darksalmon; margin-right: 3px"
              >mdi-circle-small
            </v-icon>
            Days tardy
            <div>
              <v-icon
                style="width: 7px; height: 10px; font-size: 30px; color: darkred; margin-right: 3px"
                >mdi-circle-small
              </v-icon>
              Days absent
            </div>
          </div>
        </th>
        <!--              12-->
        <th style="">
          <div>Disc. referrals</div>
          <div>
            <v-icon
              style="width: 7px; height: 10px; font-size: 14px; color: darkred; margin-right: 6px"
              >mdi-power-off
            </v-icon>
            last term
          </div>
          <div>
            <v-icon
              style="width: 7px; height: 10px; font-size: 14px; color: darkred; margin-right: 6px"
              >mdi-plus
            </v-icon>
            this term
          </div>
        </th>
      </tr>
      <template v-for="(wordInfo, wordIndex) in periodDetailData">
        <tr
          :key="`${wordIndex}`"
          style="padding: 0; position: relative"
          :style="{
            'border-top':
              wordIndex === 0 ? '2px solid darkgray' : '1px solid lightgray',
            'background-color': 'white'
          }"
        >
          <!--                     1. section-->
          <td
            v-if="wordIndex === 0"
            :rowspan="periodDetailData.length"
            style="border-right: 1px dashed darkgray; "
            :style="{ 'background-color': periodDetailAllData.color + '20' }"
          >
            <div
              style="position:fixed; top: 60%; margin: 10px 20px; text-align: center; font-weight: bold; font-size: 18px; width: 20px;"
            >
              {{ periodDetailAllData.section_name }}
            </div>
          </td>
          <!--                    2. category-->
          <td
            v-if="wordIndex === 0"
            :rowspan="periodDetailData.length"
            style="margin: 10px 20px; text-align: center; font-weight: bold;font-size: 18px; border-right: 1px dashed darkgray; "
            :style="{ 'background-color': periodDetailAllData.color + '20' }"
          >
            <div
              style=" position:fixed; top: 60%; margin: 10px 20px; text-align: center; font-weight: bold; font-size: 18px; width: 20px;"
            >
              {{ periodDetailAllData.cat_representative_name }}
            </div>
          </td>
          <!--                    3. word-->
          <td
            style=" text-align: center; border-right: 1px solid darkgrey;"
            :style="{ 'background-color': periodDetailAllData.color + '20' }"
            @click="
              $router.push({
                name: 'ExplorePeriodTimeTable',
                query: {
                  dataSet: $store.getters.getSelectedDataSet,
                  period: $route.query.period,
                  cateKo: $route.query.cateKo,
                  word:wordInfo.word,
                }
              })
            "
          >
            <span style="font-weight: bold; font-size: 1rem">
              <v-chip
                outlined
                style="margin: 0 12px; font-size: 17px; font-weight: 400"
                :style="{}"
                >{{ wordInfo.word }}
              </v-chip>
            </span>
          </td>
          <!--                    4. 육각형-->
          <td style="border-right: 1px solid darkgray;">
            <v-icon style="color: darkred; font-size: 30px;"
              >{{
                numberToHexagon(
                  Math.round(wordInfo.col_1_change_ratio * 10) >= 6
                    ? 6
                    : Math.round(wordInfo.col_1_change_ratio * 10)
                )
              }}
            </v-icon>
            <v-icon style="color: darkred; font-size: 30px;"
              >{{
                numberToHexagon(
                  Math.round(wordInfo.col_1_change_ratio * 10) - 6 > 0
                    ? Math.round(wordInfo.col_1_change_ratio * 10) - 6
                    : 0
                )
              }}
            </v-icon>
          </td>
          <!--                    5-->
          <td
            style="width: 50px; font-size: 12px; text-align: center; font-weight: 500"
          >
            {{ Math.round(wordInfo.col_1_change_ratio * 100) }}%
          </td>
          <!--                    6-->
          <td>
            <canvas
              :id="`barChart-${wordIndex}`"
              style="display: inline-block; width: 100%; height: 25px"
            ></canvas>
          </td>
          <!--                    7-->
          <td style=""></td>
          <!--                    8-1-->
          <td style="">
            <canvas
              :id="`lineChart-${wordIndex}-1`"
              style="display: inline-block; width: 100%; height: 33px; "
            ></canvas>
          </td>
          <!--                    8-2-->
          <td style="">
            <canvas
              :id="`lineChart-${wordIndex}-2`"
              style="display: inline-block; width: 100%; height: 30px; "
            ></canvas>
          </td>
          <!--                    8-3-->
          <td style="">
            <canvas
              :id="`lineChart-${wordIndex}-3`"
              style="display: inline-block; width: 100%; height: 30px; "
            ></canvas>
          </td>
          <!--                    9-->
          <td
            style="; font-size: 10px; text-align: center; font-weight: 500"
          ></td>
          <!--                    10. 게이지  -->
          <td style="width: 10%; border-right: 1px solid darkgray ">
            <div style="position: relative;top: -1px; width: 90%; left: 4%;">
              <div
                style="position:absolute; left: 0px; display: inline-block;background-color: #ff000033; height: 11px; top: -4px; border-top: 2px solid #ffb3b3; border-bottom: 2px solid #ffb3b3; transition-duration: 1s"
                :style="{ width: `${getRandomInt(1, 70)}%` }"
              ></div>
              <div
                style="position:absolute; display: inline-block;left: 0px; border-right: 3px solid darkred;height: 11px;top: -4px; transition-duration: 1s "
                :style="{ width: `${getRandomInt(70, 100)}%` }"
              ></div>
              <div
                style="position:absolute; display: inline-block; left: 0px;background-color:darkred; height:3px;transition-duration: 1s"
                :style="{ width: `${getRandomInt(1, 70)}%` }"
              ></div>
            </div>
          </td>
          <!--                    11-->
          <td style="width: 100px;">
            <v-icon
              v-for="x in 2"
              :key="x + 46345724561234"
              style="width: 7px; font-size: 30px; color: darksalmon; transition-duration: 1s"
              >mdi-circle-small
            </v-icon>
            <v-icon
              v-for="y in getRandomInt(1, 6)"
              :key="y + 4123412454567"
              style="width: 7px; font-size: 30px; color: darkred; transition-duration: 1s"
              >mdi-circle-small
            </v-icon>
          </td>
          <!--                    12-->
          <td style="">
            <table style="width: 100%; position: relative; top: 13px;">
              <tr style="border-left: 1px dashed darkgrey; ">
                <td
                  v-for="i in 5"
                  :key="i"
                  style="border-right: 1px dashed darkgrey; width: 10px; height: 15px"
                ></td>
              </tr>
            </table>

            <div style="position:relative;">
              <v-icon
                v-for="j in getRandomInt(1, 2)"
                :key="j + 8523475813745325"
                style="position:absolute;font-size: 14px; color: darkred; transition-duration: 1s"
                :style="{ left: `${getRandomInt(1, 80)}%` }"
                >mdi-power-off
              </v-icon>
              <v-icon
                v-for="k in getRandomInt(1, 3)"
                :key="k + 118974238915902"
                style="font-size: 14px; bottom: 7px; color: darkred; transition-duration: 1s"
                :style="{ left: `${k * getRandomInt(-10, 15)}%` }"
                >mdi-plus
              </v-icon>
            </div>
          </td>
        </tr>
      </template>
      <template v-if="!chartjsLoad">
        <tr
          v-for="n in periodDetailDataRowNumber"
          :key="`${n}-15696`"
          style="padding: 0; position: relative;border-top: 1px solid darkgray;"
        >
          <!--                     1. section-->
          <td style="border-right: 1px dashed darkgray; ">
            <v-skeleton-loader
              type="chip"
              style="margin: 5px;"
            ></v-skeleton-loader>
          </td>
          <!--                    2. category-->
          <td
            style="margin: 10px 20px; text-align: center; font-weight: bold;font-size: 18px; border-right: 1px dashed darkgray; "
          >
            <v-skeleton-loader
              type="chip"
              style="margin: 5px;"
            ></v-skeleton-loader>
          </td>
          <!--                    3. word-->
          <td style=" text-align: center; border-right: 1px solid darkgrey;">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </td>
          <!--                    4. 육각형-->
          <td style="border-right: 1px solid darkgray;">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </td>
          <!--                    5-->
          <td
            colspan="10"
            style="width: 50px; font-size: 12px; text-align: center; font-weight: 500;"
          >
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </td>
        </tr>
      </template>
      <tr id="bottom" style="">
        <td colspan="15" style=""></td>
      </tr>
    </table>

    <div
      v-if="
        periodDetailAllData &&
          periodDetailAllData.data.length > periodDetailDataRowNumber
      "
      style="height: 0px"
    >
      <div
        style="height: 100px; background: linear-gradient(rgba(53, 48, 48, 0) 0%, rgb(255, 255, 255) 50%); position: relative; top: -100px;"
      >
        <div
          style="text-align: center;position: relative; top: 52px; font-size: 14px"
        >
          총 {{ periodDetailAllData.data.length }}개 결과 중
          {{ periodDetailDataRowNumber }}개
        </div>
        <v-progress-circular
          style="margin-left: 49%;position: relative; top: 55px; text-align: center;vertical-align: bottom; font-size: 35px"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <!--        <v-icon v-else style="margin-left: 49%;position: relative; top: 50px; text-align: center;vertical-align: bottom; font-size: 35px">mdi-arrow-up-bold-circle-outline</v-icon>-->
      </div>
    </div>
  </div>
</template>
<!--     -->
<script>
import Chart from "chart.js";
import EventBus from "../../../EventBus";
import axios from "axios";

export default {
  name: "PeriodTable",
  data() {
    return {
      periodColName: {
        col1: "",
        col2: "",
        col3: ""
      },
      periodDetailAllData: null,
      periodDetailData: null,
      periodDetailDataRowNumber: 20,
      colors: this.$store.getters.getColors,
      chartInstance: {},
      previousElementIds: [],
      chartjsLoad: false
    };
  },
  async created() {
    EventBus.$on("reloadExploreDetailPeriodTable", async selectedPeriod => {
      this.periodDetailDataRowNumber = 20; // 탭 눌렀을 때 초기화
      await this.getPeriodDetailData(selectedPeriod);
      this.updateChart();
    });

    await this.getPeriodDetailData(this.$route.query.period);
    this.updateChart();
    this.setInfinityScroll();
    this.chartjsLoad = true;
  },
  computed: {},
  methods: {
    updateChart: async function() {
      this.$nextTick(function() {
        const currentChartjsElementIds = this.getCurrentChartjsElementIds();

        // 생성된 엘리먼트 array
        const createdElementIds = currentChartjsElementIds.filter(
          x => !this.previousElementIds.includes(x)
        );

        // 제거된 엘리먼트 array
        const missingElementIds = this.previousElementIds.filter(
          x => !currentChartjsElementIds.includes(x)
        );

        // 제거된 엘리먼트 array 객체에서 제거
        missingElementIds.forEach(ElementId => {
          delete this.chartInstance[ElementId];
        });

        this.previousElementIds = [];

        this.periodDetailData.forEach((wordInfo, wordIndex) => {
          //// bar chart setting ////
          const BarChartElementId = `barChart-${wordIndex}`;
          const barChartData = wordInfo.col_2_binary.bin.relative_frequency;

          // 새롭게 생성된 엘리먼트
          if (createdElementIds.includes(BarChartElementId)) {
            this.chartInstance[BarChartElementId] = this.createBarChart(
              BarChartElementId,
              barChartData
            );
          }
          // 기존에 있는것 업데이트
          else {
            this.chartInstance[
              BarChartElementId
            ].data.datasets[0].data = barChartData;
            this.chartInstance[BarChartElementId].update();
          }
          this.previousElementIds.push(BarChartElementId);
          //// bar chart setting end ////

          //// line chart setting ////
          const columnIndex = this.getArray(3); // [0, 1, 2] => 1줄에 3개의 line chart
          columnIndex.forEach(idx => {
            const lineChartElementId = `lineChart-${wordIndex}-${idx + 1}`;
            const lineChartData =
              wordInfo[`col_${idx + 3}_multi`].bin.relative_frequency;

            // 새롭게 생성된 엘리먼트
            if (createdElementIds.includes(lineChartElementId)) {
              this.chartInstance[lineChartElementId] = this.createLineChart(
                lineChartElementId,
                lineChartData
              );
            }
            // 기존에 있는것 업데이트
            else {
              this.chartInstance[
                lineChartElementId
              ].data.labels = this.arrayMaker(lineChartData.length);
              this.chartInstance[
                lineChartElementId
              ].data.datasets[0].data = lineChartData;
              this.chartInstance[
                lineChartElementId
              ].data.datasets[0].pointRadius = this.pointRadiusMaker(
                lineChartData.length
              );
              this.chartInstance[lineChartElementId].update();
            }
            this.previousElementIds.push(lineChartElementId);

            // 각 기간별로 컬럼 명 변경
            if (wordIndex === 0) {
              this.periodColName.col1 = wordInfo.col_3_multi.name;
              this.periodColName.col2 = wordInfo.col_4_multi.name;
              this.periodColName.col3 = wordInfo.col_5_multi.name;
            }
          });
          //// line chart setting end ////
        });
      });
    },
    numberToHexagon: function(level) {
      const hexagon = {
        0: "mdi-hexagon-outline",
        1: "mdi-hexagon-slice-1",
        2: "mdi-hexagon-slice-2",
        3: "mdi-hexagon-slice-3",
        4: "mdi-hexagon-slice-4",
        5: "mdi-hexagon-slice-5",
        6: "mdi-hexagon-slice-6"
      };

      if (level < 0) {
        return hexagon[0];
      } else if (0 <= level && level < 7) {
        return hexagon[level];
      } else {
        return hexagon[6];
      }
    },
    getCurrentChartjsElementIds: function() {
      const elementIds = [];

      this.periodDetailData.forEach((wordInfo, wordIndex) => {
        const BarChartElementId = `barChart-${wordIndex}`;
        elementIds.push(BarChartElementId);

        const columnIndex = this.getArray(3); // [0, 1, 2] => 1줄에 3개의 line chart
        columnIndex.forEach(idx => {
          const lineChartElementId = `lineChart-${wordIndex}-${idx + 1}`;
          elementIds.push(lineChartElementId);
        });

        return elementIds;
      });
      return elementIds;
    },
    sumAllWordLengthInCategory: function(sectionInfo) {
      return sectionInfo.section_categories
        .map(item => item.words.length)
        .reduce((prev, curr) => prev + curr, 0);
    },
    getRandomInt: function(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
    },
    getPeriodDetailData: async function(selectedPeriodTargetNo) {
      const dataSetNameEn = this.$store.getters.getDataSet.find(
        obj => obj.dataSetRepresentativeName === this.$route.query.dataSet
      ).dataSet;
      // dataSet에 맞는 카테고리 데이터 불러오기
      const requestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/timeBrowse/getSecondData?dataSet=${dataSetNameEn}&target=${selectedPeriodTargetNo}&date=2021-02-16&category=${this.$route.query.cate}`;
      console.log(requestUrl);

      const periodDetailData = await axios.get(requestUrl, {
        headers: {
          Authorization: this.$store.getters.getLoginToken
        }
      });
      this.periodDetailAllData = periodDetailData.data;
      this.periodDetailData = this.periodDetailAllData.data.slice(
        0,
        this.periodDetailDataRowNumber
      );
    },
    createLineChart: function(elementId, data) {
      const pointRadius = this.pointRadiusMaker(data.length);

      //adding custom chart type
      Chart.defaults.multicolorLine = Chart.defaults.line;
      Chart.controllers.multicolorLine = Chart.controllers.line.extend({
        draw: function(ease) {
          let startIndex = 0;
          const meta = this.getMeta();
          const points = meta.data || [];
          const colors = this.getDataset().colors;
          const area = this.chart.chartArea;
          const originalDatasets = meta.dataset._children.filter(function(
            data
          ) {
            return !isNaN(data._view.y);
          });

          function _setColor(newColor, meta) {
            meta.dataset._view.borderColor = newColor;
          }

          if (!colors) {
            Chart.controllers.line.prototype.draw.call(this, ease);
            return;
          }

          for (let i = 2; i <= colors.length; i++) {
            if (colors[i - 1] !== colors[i]) {
              _setColor(colors[i - 1], meta);
              meta.dataset._children = originalDatasets.slice(startIndex, i);
              meta.dataset.draw();
              startIndex = i - 1;
            }
          }

          meta.dataset._children = originalDatasets.slice(startIndex);
          meta.dataset.draw();
          meta.dataset._children = originalDatasets;

          points.forEach(function(point) {
            point.draw(area);
          });
        }
      });

      const ctx = document.getElementById(elementId).getContext("2d");

      const lineChartInstance = new Chart(ctx, {
        type: "multicolorLine",
        data: {
          labels: this.arrayMaker(data.length),
          datasets: [
            {
              label: "TeamB Score",
              data: data,
              pointRadius: pointRadius,
              backgroundColor: "white",
              borderColor: "darkred",
              fill: false,
              lineTension: 0,
              radius: 5,
              colors: this.getMulticolor()
            }
          ]
        },
        showTooltips: false,
        options: {
          tooltips: {
            enabled: false
          },
          hover: {
            filter: {
              type: "none"
            }
          },
          layout: {
            padding: {
              top: 3,
              bottom: 3,
              left: 7,
              right: 7
            }
          },
          responsive: false,
          title: {
            display: false,
            position: "top",
            text: "Line Graph",
            fontSize: 18,
            fontColor: "#111"
          },
          legend: {
            display: false,
            position: "bottom",
            labels: {
              fontColor: "#333",
              fontSize: 10
            }
          },

          scales: {
            yAxes: [
              {
                display: false
              }
            ],
            xAxes: [
              {
                display: false
              }
            ]
          }
        }
      });
      return lineChartInstance;
    },
    createBarChart: function(elementId, data) {
      const barChartInstance = new Chart(document.getElementById(elementId), {
        type: "bar",
        data: {
          labels: this.arrayMaker(data.length),
          datasets: [
            {
              backgroundColor: ["#e8c3b9", "#c45850"],
              data: data
            }
          ]
        },
        showTooltips: false,
        tooltips: {
          enabled: false
        },
        options: {
          tooltips: {
            enabled: false
          },
          hover: {
            filter: {
              type: "none"
            }
          },
          events: [],
          responsive: false,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            yAxes: [
              {
                display: false,
                stacked: true
              }
            ],
            xAxes: [
              {
                display: false,
                stacked: true
              }
            ]
          },
          showTooltips: false
        }
      });
      return barChartInstance;
    },
    arrayMaker: function(arrayLength) {
      const arr = [];

      for (let i = 0; i < arrayLength; i++) {
        arr.push(0);
      }
      return arr;
    },
    pointRadiusMaker: function(dataLength) {
      const pointRadius = this.arrayMaker(dataLength - 1);
      pointRadius.push(2.5);
      return pointRadius;
    },
    getArray: function(length) {
      return [...Array(length).keys()];
    },
    getMulticolor: function() {
      return [
        "",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green",
        "red",
        "green"
      ];
    },
    setInfinityScroll: function() {
      const bottom = document.querySelector("#bottom");
      const intersectionObserver = new IntersectionObserver(async entries => {
        if (entries.some(entry => entry.intersectionRatio > 0)) {
          this.periodDetailDataRowNumber =
            this.periodDetailAllData.data.length <
            15 + this.periodDetailDataRowNumber
              ? this.periodDetailAllData.data.length
              : 15 + this.periodDetailDataRowNumber;
          this.periodDetailData = this.periodDetailAllData.data.slice(
            0,
            this.periodDetailDataRowNumber
          );
          await this.updateChart();
        }
      });
      intersectionObserver.observe(bottom);
    }
  }
};
</script>

<style scoped>
tr > th,
td {
}

tr > th {
  vertical-align: bottom;
  font-weight: 300;
  font-size: 10px;
  text-align: left;
}

#content > div > div.col > table > tr > td {
}

#content > div > div > div > table > tr:last-child {
  border-bottom: 2px solid darkgray;
}

/*tooltip*/
[data-tooltip-text]:hover {
  position: relative;
}

[data-tooltip-text]:hover:after {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  content: attr(data-tooltip-text);

  margin-bottom: 10px;
  top: 130%;
  left: 0;
  padding: 7px 12px;
  position: absolute;
  min-width: 350px;
  max-width: 300px;
  word-wrap: break-word;

  z-index: 9999;

  font-weight: bold;
  text-align: center;
}
</style>
