<template>
  <v-container style="max-width: none; margin:0">
    <v-row>
      <v-col cols="12">
        <DetailPeriodTab></DetailPeriodTab>
        <DetailPeriodTable></DetailPeriodTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailPeriodTable from "../../../components/explore_period/second_page/DetailPeriodTable";
import DetailPeriodTab from "../../../components/explore_period/second_page/DetailPeriodTab";

export default {
  name: "DetailPeriodTablePage",
  components: {
    DetailPeriodTable, DetailPeriodTab
  }
};
</script>

<style scoped>



</style>
